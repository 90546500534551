import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "../../assests/logo.svg";

//BEM -> Block Element Modifier
const Menu = () => {
  return (
    <>
      <p>
        <Link to="/about">What is KiDD.pro?</Link>
      </p>
      <p>
        <Link to="/FAQ">FAQ</Link>
      </p>
      <p>
        <a href="#features">Services</a>
      </p>
      <p>
        <Link to="/contact-us">Contact Us</Link>
      </p>
      <p>
        <Link to="/prices">Pricing</Link>
      </p>
    </>
  );
};
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="kiddpro__navbar">
      <div className="kiddpro__navbar-links">
        <div className="kiddpro__navbar-links_logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="kiddpro__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="kiddpro__navbar-sign">
        <p>Sign In</p>
        <button type="button">Sign Up</button>
      </div>
      <div className="kiddpro__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="kiddpro__navbar-menu_container scale-up-center">
            <div className="kiddpro__navbar-menu_container-links">
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
