import React from "react";
import "./App.css";
import { Price, Terms, Privacy, Home, Contact, WhatIs, FAQ } from "./Routes";
import ScrollToTop from "./components/ScrollToTop";

import { ParallaxProvider } from "react-scroll-parallax";
import { Routes, Route } from "react-router-dom";

const App = () => {
  // const [email, setEmail] = useState(0);

  // const getEmail = (email) => {
  //   setEmail(email);
  // };

  return (
    <ParallaxProvider>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/prices" element={<Price />} />
          <Route exact path="/about" element={<WhatIs />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/FAQ" element={<FAQ />} />
        </Routes>
      </ScrollToTop>
    </ParallaxProvider>
  );
};

export default App;
