import React from "react";
import { Feature } from "../../components";
import Section from "../../components/Section/Section";
import "./features.css";

const featuresData = [
  {
    title: "Dependable",
    text: "The life of a business owner can be unpredictable. Your webservice shouldn't be. Our platform boasts an amazing 99.99% uptime record. Giving you the confidence that you will always be there when your customers search for you.",
  },
  {
    title: "Fast",
    text: "Speed is crucial to the end user experience. We utilize the latest technology in software and hardware to ensure that your website delivers to your customers blazing fast",
  },
  {
    title: "Secure",
    text: "Protect your customers when they visit your website. All hosting plans include a FREE SSL certificate to protect your website from malicious bots and hackers.",
  },
  {
    title: "Smart",
    text: "Gain valuable marketing insights into your business like never before. All hosting plans include a FREE monthly analytics report.",
  },
];

const Features = () => {
  return (
    <div className="kiddpro__features sec__padding" id="features">
      <div className="kiddpro__features-heading">
        <Section duration="0.9" delay="0" directionFrom="-500">
          <h1 className="gradient__text">
            Too busy running your business to run a website? Take advantage of
            our hosting plans!
          </h1>
        </Section>

        <a href="#contact">
          <p>Don't Wait. Message Us Today!</p>
        </a>
      </div>
      <div className="kiddpro__features-container">
        <Section duration="0.9" delay="0.7" directionFrom="-500">
          {featuresData.map((item, index) => {
            return (
              <Feature
                title={item.title}
                text={item.text}
                key={item.title + index}
              />
            );
          })}
        </Section>
      </div>
    </div>
  );
};

export default Features;
