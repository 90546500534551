import React from "react";
import { Link } from "react-router-dom";
import "./cta.css";

const CTA = () => {
  return (
    <div className="kiddpro__cta">
      <div className="kiddpro__cta-content">
        <p>Request Early Access to Get Started</p>
        <h3>Register Today & start exploring the endless possibilities.</h3>
      </div>
      <div className="kiddpro__cta-button">
        <Link to="/contact-us">
          <button type="button">GET STARTED</button>
        </Link>
      </div>
    </div>
  );
};

export default CTA;
