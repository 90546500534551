import { Navbar } from "../../components";
import { Footer, WhatKiDDpro } from "../../containers";

function WhatIs() {
  return (
    <>
      <div className="gradient__bg-header">
        <Navbar />
        <WhatKiDDpro />
      </div>
      <Footer />
    </>
  );
}

export default WhatIs;
