import React from "react";
import ScrollToTop from "../../components/ScrollToTop";

import { Footer, Possibility, Header } from "../../containers";

import { CTA, Brand, Navbar } from "../../components";

const Home = () => {
  return (
    <ScrollToTop>
      <div className="App">
        <Navbar />
        <div className="gradient__bg-header">
          <Header />
        </div>
        <Brand />
        <Possibility />
        <CTA />
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default Home;
