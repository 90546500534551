import React from "react";
import "./whatKiDDpro.css";
import Feature from "../../components/feature/Feature";
import Section from "../../components/Section/Section";
import ai from "../../assests/ai.png";
import { Parallax } from "react-scroll-parallax";

const WhatKiDDpro = () => {
  return (
    <Section delay="0.5" duration="0.9" directionFrom="-300">
      <div className="gradient__text sec__margin kiddpro__whatkiddpro-header">
        <div className="kiddpro_whatkiddpro-header__text">
        <p>Kilcorse Design & Development</p>
        <div>
          <p>or just</p>
        </div>
        <div>
          <p>KiDD.pro</p>
        </div>
        </div>
        <img src={ai} alt="KiDD.pro logo" />
      </div>

      <div className="kiddpro__whatkiddpro sec__margin" id="wkiddpro">
        <div className="kiddpro__whatkiddpro-feature">
          <Feature
            title="What is KiDD.pro?"
            text="KiDD.pro (that's: kid *dot* pro) is more than just a web service. We are a small business that believes in small businesses. So we have crafted our services and products to give every business owner, no more how big or small, access to the same modern and professional tools that were once out of reach."
          />
        </div>
        <div className="kiddpro__whatkiddpro-heading">
          <Parallax speed={5}>
            <h1 className="gradient__text">
              More than a Website. It's your first impression
            </h1>
          </Parallax>
          <a href="#contact">
            <p>Contact Us</p>
          </a>
        </div>
        <Parallax speed={5}>
          <div className="kiddpro__whatkiddpro-container">
            <Feature
              title="Website Development and Design"
              text="A sleek, fast, and professional design that reflects your business. No matter what you do, 76% of all consumers search for a business before going. Put your best foot forward"
            />
            <Feature
              title="Hosting"
              text="Servers? DNS Records? SSL Certificates? Managing a website is hard. Let us make it easy. Take advantage of our affordable hosting services and we'll handle everything."
            />
            <Feature
              title="Analytics and Marketing"
              text="On top of being fully designed with SEO in mind, all of our hosting plans come with a FREE monthly analytics report. Giving you deeper insights into how your customer behaves."
            />
          </div>
        </Parallax>
      </div>
    </Section>
  );
};

export default WhatKiDDpro;
