import React from "react";
import "./header.css";
import people from "../../assests/people.png";
import Section from "../../components/Section/Section";
import ai from "../../assests/ai.png";
import { Parallax } from "react-scroll-parallax";

const Header = ({ getEmail }) => {
  return (
    <div className="kiddpro__header sec__padding" id="home">
      <div className="kiddpro__header-content">
        <div className="kiddpro__header-content_title">
        <Section duration="0.9" delay="0.5" directionFrom="-300">
          <div>
          <h1 className="gradient__text">
            Modern Web Solutions for the Modern Small Business
          </h1>
          </div>
        </Section>
        <Parallax speed={-5}>
          <Section duration="0.9" directionFrom="-300" delay="0.75">
            <p>
              It takes hard work, sleepless nights, and unyielding drive to
              start a small business. We know, we are one. And after all of your
              hard work, you realize you need a website. Building and managing
              it yourself is a headache you don't need, and hiring a
              professional developer is too expensive. Not anymore.
            </p>
          </Section>
          
          <Section duration="0.9" delay="1" directionFrom="-300">
            <div className="kiddpro__header-content__input">
              <input
                type="email"
                name="email"
                placeholder="Your Email Address"
              />
              <button type="submit">Get Started</button>
            </div>
          </Section>
          <Section duration="0.9" delay="1.25" directionFrom="-300">
            <div className="kiddpro__header-content__people">
              <img src={people} alt="people" />
              <p>
                Read what other Business leaders in the community are saying!
              </p>
            </div>
          </Section>
        </Parallax>
        </div>
        <div className="kiddpro__header-graphic">
        <Parallax speed={-5}>
        <div className="kiddpro__header-img">
          <img src={ai} alt="ai" />
        </div>
      </Parallax>
      <Parallax speed={4}>
        <Section delay="1.5" duration="0.9" directionFrom="500">
          <h1 className="gradient__text">Are You Ready?</h1>
        </Section>
      </Parallax>
      </div>
      </div>
      </div>
  );
};

export default Header;
