import React from "react";
import { Parallax } from "react-scroll-parallax";
import "./feature.css";

const Feature = ({ title, text }) => (
  <div className="kiddpro__features-container__feature">
    <Parallax speed={5}>
    <div className="kiddpro__features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    </Parallax>
    <Parallax speed={3}>
    <div className="kiddpro__features-container__feature-text">
      <p>{text}</p>
    </div>
    </Parallax>
  </div>
);

export default Feature;
