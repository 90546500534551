import { ContactForm, Navbar } from "../../components";
import ScrollToTop from "../../components/ScrollToTop";
import { Footer } from "../../containers";

function Contact() {
  return (
    <>
      <ScrollToTop />
      <div>
        <Navbar />

        <ContactForm />
        <Footer />
      </div>
    </>
  );
}

export default Contact;
