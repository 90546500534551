import React from "react";
import "./footer.css";
import Section from "../../components/Section/Section";
import { Link } from "react-router-dom"
import kiddproLogo from "../../assests/logo.svg";

const Footer = ({ email }) => {
  return (
    <div id="contact" className="kiddpro__footer gradient__bg sec__padding">
      <Section duration="0.9" delay="0.5" directionFrom="-300">
        <div className="kiddpro__footer-heading">
          <h1 className="gradient__text">
            Take Control of your Online Presence. Today.
          </h1>
        </div>
      </Section>

      <div className="kiddpro__footer-links">
        <div className="kiddpro__footer-links_logo">
          <img src={kiddproLogo} alt="logo" />
          <p>KiDD.pro, All Rights Reserved</p>
        </div>
        <div className="kiddpro__footer-links_div">
          <h4>Links</h4>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p>
        </div>
        <div className="kiddpro__footer-links_div">
          <h4>Company</h4>
          <p><Link to="/terms">Terms & Conditions</Link></p>
          <p><Link to="/privacy">Privacy Policy</Link></p>
          <p><Link to="/contact-us">Contact</Link></p>
        </div>
      </div>
      <div className="kiddpro__footer-copyright">
        <p>© 2022 KiDD.pro. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
