import { Navbar, CTA } from "../../components";
import HeroImage from "../../components/HeroImage/HeroImage";
import toledoImg from "../../assests/toledo.png";
import { Footer, Features } from "../../containers";

import "./price.css";

function Price() {
  return (
    <>
      <Navbar />
      <HeroImage src={toledoImg} description="Toledo, Ohio" />
      <div className="kiddpro__price-pricebox">
        <div className="kiddpro__price-website__price">
          <div className="kiddpro__price-website__price-content">
            <h2>BUILD A MODERN AND BEAUTIFUL WEBSITE STARTING AT:</h2>
            <h3>$1500</h3>
          </div>
        </div>
        <div className="kiddpro__price-website__price">
          <div className="kiddpro__price-website__price-content">
            <h2>Lighting Fast and Dependable Hosting Starting at:</h2>
            <h3>$75/month</h3>
          </div>
        </div>
      </div>
      <Features />
      <CTA />
      <Footer />
    </>
  );
}

export default Price;
