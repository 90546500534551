function HeroImage(props){
    return(
        <>
        <div className="bg">
        <div className="hero">
            <img src={props.src} alt={props.description}/>
        </div>
        </div>
        </>
    );
}

export default HeroImage;