import { Navbar, CTA } from "../../components";
import { Footer } from "../../containers";

import "./FAQ.css";
const FAQ = () => {
  const faqData= [
    {
      question: "Questions about Kidd.Pro",
      answer: "Answer about Kidd.pro"
    },
    {
      question: "Questions about Kidd.Pro",
      answer: "Answer about Kidd.pro"
    },
    {
      question: "Questions about Kidd.Pro",
      answer: "Answer about Kidd.pro"
    },
    {
      question: "Questions about Kidd.Pro",
      answer: "Answer about Kidd.pro"
    },
  ];
  return (
    <>
      <div className="gradient__bg-header">
        <Navbar />
        <div className="gradient__text sec__margin kiddpro__faq-header">
          <p>FAQ</p>
        </div>
        <div className="kiddpro__faq-list">
        {faqData.map((item, index)=>{
          return(
            <div className="kiddpro__faq-box">
            <div className="kiddpro__faq">
              <div className="flex__row">
                <div className="kiddpro__faq-content">
                  <ul>
                    <li>
                      <h2>{item.question}</h2>
                    </li>
                    <li>
                      <h3>{item.answer}</h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          );
        })}
        </div>
        <CTA />
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
