import React, { useState } from "react";
import Section from "../Section/Section";
import "./contactForm.css";

const ContactForm = ({ email }) => {
  const [status, setStatus] = useState("Submit");
  console.log("Begin Fetch");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };

    let response = await fetch("https://us-central1-mailingserver-b4515.cloudfunctions.net/app/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    console.log(result);
    alert(result.status);
    [name.value, message.value, email.value] = Array(3).fill("");
  }; 
  return (
    <div className="kiddpro__contact">
      <div className="kiddpro__contact-content">
        <Section duration="0.9" delay="0.75" directionFrom="-300">
          <h3>Want to learn more?</h3>
          <h1>Contact Us Today!</h1>
        </Section>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" required />
          <label htmlFor="email">Email:</label>
          <input default={email} type="email" id="email" required />
          <div className="kidpro__contact-message">
            <label htmlFor="message">Message:</label>
            <input id="message" required />
          </div>
          <button className="kiddpro__contact-content_submit" type="submit">
            {status}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
