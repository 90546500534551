import React from "react";
import "./possibility.css";
import Section from "../../components/Section/Section";
import possibilityImage from "../../assests/possibility.png";

const Possibility = () => {
  return (
    <div className="kiddpro__possibility sec__padding" id="possibility">
      <div className="kiddpro__possibility-img">
        <img src={possibilityImage} alt="possibility" />
      </div>
      <div className="kiddpro__possibility-content">
        <a href="#contact">
          <h4>Contact Us Now to get Started!</h4>
        </a>
        <Section duration="0.9" delay=".5" directionFrom="-500">
          <h1 className="gradient__text">
            This is your chance to gain access to this new service!
          </h1>
          <p>
            A new platform of web services catered to the small business owner. Everything we do we do with you in mind. Simple and easy to understand process at an affordable prices. Send us a message
          </p>
        </Section>
        <h4>Request Early Access to get Started!</h4>
      </div>
    </div>
  );
};

export default Possibility;
